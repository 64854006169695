import { Injectable, inject } from '@angular/core';
import { type ActivatedRouteSnapshot, type RouterStateSnapshot, type UrlTree, Router } from '@angular/router';

import { UserService } from '../services/user.service';

@Injectable()
export class AuthGuard {
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (
      this.userService.isAuthenticated === null ||
      (this.userService.isAuthenticated && this.userService.permissions === null)
    ) {
      this.waitForAuthService(state.url);
      return false;
    } else {
      if (this.userService.isAuthenticated) {
        return true;
      }

      this.userService.lastRedirectedRoute = state.url;
      this.router.navigate(['login']);
      return false;
    }
  }

  private waitForAuthService(url: string): void {
    setTimeout(() => {
      if (
        this.userService.isAuthenticated === null ||
        (this.userService.isAuthenticated && this.userService.permissions === null)
      ) {
        this.waitForAuthService(url);
      } else {
        if (this.userService.isAuthenticated) {
          this.router.navigateByUrl(url);
        } else {
          this.userService.lastRedirectedRoute = url;
          this.router.navigate(['login']);
        }
      }
    }, 50);
  }
}
