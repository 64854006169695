import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { type User } from '../models/user';
declare let clarity: any;

@Injectable()
export class ClarityMonitoringService {
  public init(): void {
    if (!ConfigService.settings.enableClarity) {
      return;
    }

    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${ConfigService.settings.clarityKey}");`;
    document.head.appendChild(scriptElement);
  }

  public setUserInfo(user: User): void {
    if (typeof clarity === 'undefined' || !clarity) {
      console.log('clarity is undefined');
      return;
    }

    if (!user) {
      console.log('user is undefined');
      return;
    }

    clarity('set', 'userId', user.id.toString());

    if (user.companyId) {
      clarity('set', 'companyId', user.companyId.toString());
    }
  }
}
