import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import { registerPlugin } from 'ngx-filepond';

import { AuthGuard } from './guards/auth.guard';
import { PermissionsGuard } from './guards/permissions.guard';
import { AuthTokenInterceptor } from './interceptors/auth-token.interceptor';
import { AutoLogoutService } from './services/auto-logout.service';
import { ClarityMonitoringService } from './services/clarity-monitoring-service';
import { ConfigService } from './services/config.service';
import { FlatfileService } from './services/flatfile/flatfile.service';
import { GeolocationService } from './services/geolocation.service';
import { GlobalSearchService } from './services/global-search.service';
import { GoogleUtilsService } from './services/google-utils.service';
import { HelpersService } from './services/helpers.service';
import { ModalService } from './services/modal.service';
import { NotificationService } from './services/notification.service';
import { PermissionService } from './services/permission.service';
import { LocalStorageService } from './services/persistance.service';
import { SplitService } from './services/split.service';
import { StringHelpersService } from './services/string-helpers.service';
import { ToastrService } from './services/toastr.service';

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType, FilePondPluginImageExifOrientation);

@NgModule({
  providers: [
    ToastrService,
    LocalStorageService,
    ConfigService,
    ModalService,
    AuthGuard,
    PermissionsGuard,
    AutoLogoutService,
    NotificationService,
    SplitService,
    GoogleUtilsService,
    PermissionService,
    GlobalSearchService,
    GeolocationService,
    HelpersService,
    ClarityMonitoringService,
    StringHelpersService,
    FlatfileService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
