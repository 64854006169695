import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { HelpersService } from './helpers.service';
import { HttpService } from './http.service';
import { ModalService } from './modal.service';
import { LocalStorageService } from './persistance.service';
import { UserService } from './user.service';
import { LS_AUTH_DATA_KEY } from '../constants/ls-auth-data-key';
import { PAGE_URL } from '../constants/page-url';

const MINUTES_UNITL_AUTO_LOGOUT = 60; // in minutes
const CHECK_INTERVAL_VALUE = 30000; // in ms
const ACTIVITY_UPDATE_INTERVAL_VALUE = 60000; // in ms
const USER_CHANGE_CHECK_INTERVAL_VALUE = 1000; // in ms

const LAST_ACTION_STORE_KEY = 'lastAction';

@Injectable()
export class AutoLogoutService {
  private readonly userService = inject(UserService);
  private readonly persistance = inject(LocalStorageService);
  private readonly ngZone = inject(NgZone);
  private readonly http = inject(HttpService);
  private readonly modalService = inject(ModalService);
  private readonly dialog = inject(Dialog);
  private readonly router = inject(Router);
  private readonly helpers = inject(HelpersService);
  private checkInterval: any;
  private activityUpdateInterval: any;
  private userChangeCheckInterval: any;

  constructor() {
    this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    document.body.addEventListener('click', () => {
      this.reset();
    });
  }

  private get lastAction(): number {
    let value = +this.persistance.get(LAST_ACTION_STORE_KEY);
    if (!value) {
      value = Date.now();
      this.lastAction = value;
    }
    return value;
  }

  private set lastAction(value: number) {
    this.persistance.set(LAST_ACTION_STORE_KEY, value);
  }

  public init(): void {
    this.reset();
    this.ngZone.runOutsideAngular(() => {
      this.checkInterval = setInterval(() => {
        this.ngZone.run(() => {
          this.checkAutoLogout();
        });
      }, CHECK_INTERVAL_VALUE);

      this.updateOnlineStatus();
      this.activityUpdateInterval = setInterval(() => {
        this.updateOnlineStatus();
      }, ACTIVITY_UPDATE_INTERVAL_VALUE);

      this.userChangeCheckInterval = setInterval(() => {
        this.ngZone.run(() => {
          this.checkUserChange();
        });
      }, USER_CHANGE_CHECK_INTERVAL_VALUE);
    });
  }

  public updateOnlineStatus(): void {
    if (this.userService.isImpersonating) {
      return;
    }

    this.http.put(`api/users/my/activity`, {}).then(() => {});
  }

  public stop(): void {
    clearInterval(this.checkInterval);
    clearInterval(this.activityUpdateInterval);
    clearInterval(this.userChangeCheckInterval);
  }

  private reset(): void {
    this.ngZone.runOutsideAngular(() => {
      this.lastAction = Date.now();
    });
  }

  private checkUserChange(): void {
    const tokenData = this.persistance.get(LS_AUTH_DATA_KEY);

    if (
      !this.userService.isImpersonating &&
      !this.userService.isSwitchedProfile &&
      (!this.userService.isAuthenticated || !tokenData)
    ) {
      this.stop();
      this.modalService.closeModals();
      this.dialog.closeAll();
      setTimeout(() => {
        this.router.navigate([PAGE_URL.LOGIN]);
      }, 0);
    }
  }

  private checkAutoLogout(): void {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout && this.userService.isAuthenticated) {
      this.userService.isTimedOut = true;
      this.stop();
      this.modalService.closeModals();
      this.dialog.closeAll();
      setTimeout(() => {
        this.userService.logout();
      }, 0);
    }
  }
}
